











































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BiduiPreview } from "@/request/research";
@Component({
  components: {},
})
export default class Name extends Vue {
  private selectedTongues: any[] = [];
  private createdTime = "";
  private results: any = {
    患者信息: {},
    检测时间: {},
    舌象照片: {},
    舌底照片: {},
    望舌质: {},
    望舌苔: {},
    望舌下络脉: {},
  };
  private tree = [
    {
      title: "望舌质",
      val: [
        {
          title: "舌色",
          val: ["舌色", "边尖红"],
        },
        {
          title: "齿痕",
          val: [
            "齿痕",
            "严重程度",
            "齿痕数量",
            "齿痕最小面积占舌比",
            "齿痕最大面积占舌比",
            "齿痕平均面积占舌比",
          ],
        },
        {
          title: "裂纹",
          val: [
            "裂纹",
            "严重程度",
            "位置",
            "裂纹数量",
            "裂纹总像素长度",
            "裂纹最大像素长度",
            "裂纹最小像素长度",
            "裂纹平均像素长度",
          ],
        },
        {
          title: "点刺",
          val: ["点刺", "严重程度", "位置", "点刺数量"],
        },
        {
          title: "瘀点",
          val: ["瘀点", "严重程度", "位置", "瘀点数量"],
        },
        {
          title: "瘀斑",
          val: [
            "瘀斑",
            "严重程度",
            "位置",
            "瘀斑数量",
            "瘀斑最小面积占舌比",
            "瘀斑最大面积占舌比",
            "瘀斑平均面积占舌比",
          ],
        },
        {
          title: "胖瘦",
          val: ["胖瘦", "舌边距", "嘴角距", "宽宽比"],
        },
        {
          title: "老嫩",
          val: ["老嫩"],
        },
        {
          title: "舌态",
          val: ["舌态", "歪斜方向", "歪斜角度"],
        },
        {
          title: "舌神",
          val: ["舌神"],
        },
      ],
    },
    {
      title: "望舌苔",
      val: [
        {
          title: "苔色",
          val: ["苔色"],
        },
        {
          title: "苔质面积占舌比",
          val: ["苔质面积", "苔质面积占舌比"],
        },
        {
          title: "厚薄",
          val: ["厚薄"],
        },
        {
          title: "腐腻",
          val: ["腐苔", "腻苔"],
        },
        {
          title: "剥落",
          val: ["剥落", "剥落部位和范围", "剥落面积占舌比"],
        },
        {
          title: "偏全",
          val: ["偏全"],
        },
        {
          title: "真假",
          val: ["真假"],
        },
        {
          title: "润燥",
          val: ["润燥"],
        },
      ],
    },
    {
      title: "望舌下络脉",
      val: [
        {
          title: "色泽",
          val: ["色泽"],
        },
        {
          title: "长宽度",
          val: ["主干长度", "长度比", "主干宽度", "曲张"],
        },
        {
          title: "瘀斑瘀点",
          val: ["瘀斑瘀点", "瘀斑瘀点数量（左）", "瘀斑瘀点数量（右）"],
        },
        {
          title: "络脉分支",
          val: ["络脉分支", "分支数量（左）", "分支数量（右）"],
        },
        {
          title: "囊泡",
          val: ["囊泡", "囊泡数量（左）", "囊泡数量（右）"],
        },
      ],
    },
  ];

  /**
   * @description 获取数据
   */
  private fetchResult() {
    const params: any = {
      tongue_ids: this.selectedTongues.map((tongue) => tongue["tongue_id"]),
    };
    BiduiPreview(this, params).then((data: any) => {
      this.results = data;
    });
  }
  /**
   * @description 跳转定性特征编辑
   */
  private jumpEdit(index: any, type: any) {
    this.$router.push({
      path: "/main/research/tongue/biaozhu",
      query: {
        tongue_id: this.selectedTongues[index].tongue_id,
        project_id: this.selectedTongues[index].project_id,
        pType: type,
      },
    });
  }
  private previewImg(src: any) {
    window.open(src);
  }
  /**
   * @description 初始化
   */
  private mounted() {
    const arr = localStorage.getItem("selectedTongues");
    if (arr) {
      this.selectedTongues = JSON.parse(arr);
      this.fetchResult();
    }
    this.createdTime = localStorage.getItem("biduiTime") || "";
  }
}
